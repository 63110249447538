<template>
  <div v-if="loading">
    <b-row>
      <b-col cols="12" v-show="false">
        <h2>Lifetime Statistics</h2>
      </b-col>
      <b-col sm="6" md="3" v-show="false">
        <statistic-card-horizontal
          :statistic-title="$t('stat.title.user')"
          :statistic="counts.user"
          color="primary"
          icon="UserIcon"
        />
      </b-col>
      <b-col sm="6" md="3" v-show="false">
        <statistic-card-horizontal
          :statistic-title="$t('stat.title.photo')"
          :statistic="counts.photo"
          color="info"
          icon="ImageIcon"
        />
      </b-col>
      <b-col sm="6" md="3" v-show="false">
        <statistic-card-horizontal
          :statistic-title="$t('stat.title.person')"
          :statistic="counts.person"
          color="success"
          icon="UsersIcon"
        />
      </b-col>
      <b-col sm="6" md="3" v-show="false">
        <statistic-card-horizontal
          :statistic-title="$t('stat.title.identification')"
          :statistic="counts.query"
          color="warning"
          icon="SearchIcon"
        />
      </b-col>
      <b-col
        cols="10"
        class="mx-auto mb-1"
        style="border-bottom: 1px solid grey"
        v-show="false"
      />
      <b-col cols="12" v-show="false">
        <h2>Statistics</h2>
      </b-col>
      <b-col sm="12" md="8" v-show="false">
        <graph
          :title="$t('dashboard.identifications')"
          :fetch="identifications"
        />
      </b-col>
      <b-col sm="12" md="4">
        <b-card no-body>
          <b-card-header>
            <b-card-title>{{ $t("subscription.usage-quotas") }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <limits :limits="limits" />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="12" md="8">
        <graph
          :title="$t('dashboard.verifications')"
          type="verification"
          :fetch="verifications"
        />
      </b-col>
    </b-row>
  </div>
  <spinner v-else />
</template>

<script>
import { dashboard, identifications, verifications } from "@/api/stats.api";

import AggregateViews from "./components/AggregateViews.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import Graph from "./components/Graph.vue";
import Limits from "./components/Limits.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    AggregateViews,
    StatisticCardHorizontal,
    Spinner,
    Graph,
    Limits,
  },
  data() {
    return {
      loading: false,
      counts: {
        user: 0,
        photo: 0,
        person: 0,
        query: 0,
      },
      limits: {},
      identifications,
      verifications,
    };
  },
  methods: {
    async refreshData() {
      this.loading = false;
      const {
        data: { counts, series, limits },
      } = await dashboard({ organization_id: this.organization_id });

      Object.keys(this.counts).forEach(
        (key) => (this.counts[key] = counts[key])
      );
      this.series = series;
      this.limits = limits;
      this.loading = true;
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>

<style>
</style>