<template>
  <network-container :loading="preLoading">
    <b-row>
      <b-col sm="4" v-for="plan in plans" :key="plan.title">
        <plan-card :plan="plan"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="6" xl="2" lg="2" cols="6" v-for="data of Object.keys(counts)" :key="data">
        <mini-statistic-chart :value="counts[data]" :design="countOptions[data]"/>
      </b-col>
    </b-row>

    <h2>Organizations</h2>
    <simplebar class="area">
      <b-row>
        <b-col
            lg="6"
            v-for="{ organization, counts, plan, limits } in data"
            :key="organization.id"
        >
          <b-card class="card-height">
            <b-card-title class="d-flex" style="gap: 0.5rem">
            <span style="flex-grow: 1">
              {{ organization.name }}
            </span>

              <!-- TRIAL EXPIRATION -->
              <b-badge
                  variant="light-danger"
                  v-if="plan && plan.trial_ends_at"
                  pill
                  class="ml-auto"
              >
                {{ $t("subscription.trial") }} -
                {{
                  $d(new Date(organization.trial_ends_at * 1000), "long", "en")
                }}
              </b-badge>

              <!-- PLAN TITLE -->
              <b-badge
                  variant="light-warning"
                  v-if="organization.has_active_subscription"
                  pill
                  class="ml-auto"
              >
                {{ plan.plan.title }}
              </b-badge>
            </b-card-title>
            <b-row no-gutters>
              <b-col md="3" class="half-half">
                <small-stat-card
                    :label="$t('stat.title.user')"
                    :value="counts.user"
                    icon="UserIcon"
                    variant="light-primary"
                />
              </b-col>
              <b-col md="3" class="half-half">
                <small-stat-card
                    :label="$t('stat.title.photo')"
                    :value="counts.photo"
                    icon="ImageIcon"
                    variant="light-info"
                />
              </b-col>
              <b-col md="3" class="half-half">
                <small-stat-card
                    :label="$t('stat.title.person')"
                    :value="counts.person"
                    icon="UsersIcon"
                    variant="light-success"
                />
              </b-col>
              <b-col md="3" class="half-half">
                <small-stat-card
                    :label="$t('stat.title.identification')"
                    :value="counts.query"
                    icon="SearchIcon"
                    variant="light-warning"
                />
              </b-col>
              <!--              <b-col cols="12">-->
              <!--                <hr/>-->
              <!--              </b-col>-->
              <!--              <b-col md="6" v-if="plan">-->
              <!--                {{ $t("subscription.usage") }}-->
              <!--                <limits :limits="limits"/>-->
              <!--              </b-col>-->
              <!--              <b-col md="6" style="display: grid; place-items: center">-->
              <!--                <span>{{ organization.email }}</span>-->
              <!--              </b-col>-->
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </simplebar>
  </network-container>
</template>

<script>
import {aggregate} from "@/api/service-stats.api";
import NetworkContainer from "@/components/NetworkContainer.vue";
import SmallStatCard from "./components/SmallStatCard.vue";
import Limits from "./components/Limits.vue";
import CountTable from "./components/CountTable.vue";
import PlanCard from "./components/PlanCard";
import MiniStatisticChart from "@/views/admin/Dashboard/components/MiniStatisticChart";
import simplebar from "simplebar-vue";
import 'simplebar/dist/simplebar.min.css';

export default {
  components: {MiniStatisticChart, PlanCard, NetworkContainer, SmallStatCard, Limits, CountTable, simplebar},
  data() {
    return {
      preLoading: true,
      data: null,
      counts: {},
      plans: {},
      countOptions: {
        identifications: {
          text: 'Identifications',
          color: 'primary',
          icon: 'UserCheckIcon'
        },
        organizations: {
          text: 'Organizations',
          color: 'info',
          icon: 'BriefcaseIcon'
        },
        people: {
          text: 'People',
          color: 'success',
          icon: 'UsersIcon'
        },
        photos: {
          text: 'Photos',
          color: 'warning',
          icon: 'ImageIcon'
        },
        users: {
          text: 'Users',
          color: 'danger',
          icon: 'UserIcon'
        },
        verifications: {
          text: 'Verifications',
          color: 'danger',
          icon: 'ColumnsIcon'
        }
      }
    };
  },
  methods: {
    preparePlanData(plans) {
      const titles = ["Starter", "Standard", "Premium"]

      const data = {}
      titles.forEach((title, index) => {
        data[title.hashCode()] = {
          title,
          counts: {'No Data': 0},
          order: index
        }
      })

      plans.forEach((plan, index) => {
        // hash string value
        const key = plan.title.hashCode()

        if (data[key]) {
          data[key].counts[plan.status] = plan.count
        } else {
          const counts = {}
          counts[plan.status] = plan.count
          data[key] = {
            title: plan.title,
            counts,
            order: index + 2
          }
        }
      })

      return [...Object.values(data)].sort((a, b) => a.order - b.order)
    },
    async fetchData() {
      const {
        data: {data, counts, plans},
      } = await aggregate();

      this.data = data;
      this.counts = counts;
      this.plans = this.preparePlanData(plans)
      this.preLoading = false;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.half-half {
  max-width: 50%;
}

th {
  width: 100%;
}

.area {
  height: 620px !important;
  padding: 0 !important;
  overflow-x: hidden !important;
}

.card-height {
  min-height: 130px !important;
}
</style>
